import * as React from 'react'

import { PrismicRichText } from '@prismicio/react'
import { graphql, HeadFC, HeadProps, Link } from 'gatsby'
import LiteYouTubeEmbed from 'react-lite-youtube-embed'

interface DataProps {
  prismicSwVerboseContentPage: Queries.VerboseContentPageQuery['prismicSwVerboseContentPage']
}

const VerboseContentPageTemplate = ({ data }: { data: DataProps }) => {
  const tastryScript = data?.prismicSwVerboseContentPage?.data?.body1?.[0]?.primary.rich_text?.text;

  React.useEffect(() => {
    if(!tastryScript) return

    // Create the script element
    const script = document.createElement("script");

    const srcStart = tastryScript?.indexOf('src="') + 5; // Add 5 to skip past 'src="'
    const srcEnd = tastryScript?.indexOf('"', srcStart);


    script.src = tastryScript.substring(srcStart, srcEnd)

    // Attach onload handler properly
    script.onload = () => {
      const tastryEvent = new Event('load');
      window.dispatchEvent(tastryEvent);
    };

    // Append the script to the body
    document.body.appendChild(script);

    // Clean up and remove the script when the component is unmounted or refreshed
    return () => {
      if (script && document.body.contains(script)) {
        document.body.removeChild(script);
      }
    };
  }, []);

  return (
  <>
    {(data.prismicSwVerboseContentPage?.data.bannerBackgroundColor ||
      data.prismicSwVerboseContentPage?.data.bannerImage?.url) && (
      <div
        className="h-[150px] lg:h-[200px] bg-center bg-no-repeat bg-cover flex flex-col items-center justify-center mx-auto"
        style={{
          backgroundColor:
            data.prismicSwVerboseContentPage?.data.bannerBackgroundColor || '#ffffff',
          backgroundImage: `url(${
            data.prismicSwVerboseContentPage?.data.bannerImage?.url || 'none'
          })`,
          color: data.prismicSwVerboseContentPage?.data.bannerTextColor || '#000000',
        }}
      >
        <span className="text-5xl">{data.prismicSwVerboseContentPage?.data.bannerText?.text}</span>
      </div>
    )}
    <div className="lg:max-w-5xl lg:mx-auto font-secondary [&>p]:mb-4 py-8 px-4 lg:px-0">
      <PrismicRichText
        components={{
          heading1: ({ children, key }) => (
            <h1 className="text-5xl" key={key}>
              {children}
            </h1>
          ),
          heading2: ({ children, key }) => (
            <h2 className="text-4xl" key={key}>
              {children}
            </h2>
          ),
          heading3: ({ children, key }) => (
            <h3 className="text-3xl" key={key}>
              {children}
            </h3>
          ),
          heading4: ({ children, key }) => (
            <h4 className="text-2xl" key={key}>
              {children}
            </h4>
          ),
          heading5: ({ children, key }) => (
            <h5 className="text-xl" key={key}>
              {children}
            </h5>
          ),
          heading6: ({ children, key }) => (
            <h6 className="text-lg" key={key}>
              {children}
            </h6>
          ),
          paragraph: ({ children, key }) => <p key={key}>{children}</p>,
          preformatted: ({ node, key }) => <pre key={key}>{node.text}</pre>,
          strong: ({ children, key }) => <strong key={key}>{children}</strong>,
          em: ({ children, key }) => <em key={key}>{children}</em>,
          listItem: ({ children, key }) => <li key={key}>{children}</li>,
          oListItem: ({ children, key }) => <li key={key}>{children}</li>,
          list: ({ children, key }) => <ul key={key}>{children}</ul>,
          oList: ({ children, key }) => <ol key={key}>{children}</ol>,
          embed: ({ node, key }) => {
            const url = node.oembed.embed_url
            if (url.lastIndexOf('/') === -1) {
              return <React.Fragment key={key}></React.Fragment>
            }
            const videoId = url.substring(url.lastIndexOf('/') + 1, url.length)

            return (
              <div className="w-[500px] h-[300px] mx-auto">
                <LiteYouTubeEmbed
                  key={key}
                  id={videoId}
                  title="Embedded YouTube Video"
                  poster="maxresdefault"
                  noCookie
                />
              </div>
            )
          },
          hyperlink: ({ node, children, key }) => {
            const hasNoLink = !node.data.slug && !node.data.url

            if (hasNoLink) {
              return <React.Fragment key={key}></React.Fragment>
            }

            const isInternal = node.data.link_type === 'Document'
            const props = {
              className: 'text-link hover:underline',
              key,
            }

            return isInternal ? (
              <Link
                to={node.data.slug === 'sw-home-page' ? '/' : `/${node.data.uid || ''}`}
                {...props}
              >
                {children}
              </Link>
            ) : (
              <a href={node.data.url} target={node.data.target || '_self'} {...props}>
                {children}
              </a>
            )
          },
          image: ({ node, key }) => {
            const img = (
              <img
                alt={node.alt || 'Image'}
                data-copyright={node.copyright}
                height={node.dimensions.height}
                width={node.dimensions.width}
                src={node.url}
              />
            )

            return (
              <p key={key} className="flex justify-center items-center">
                {img}
              </p>
            )
          },
        }}
        fallback={<></>}
        field={data.prismicSwVerboseContentPage?.data.body?.richText}
      />
    </div>
    {tastryScript?.includes("script") &&  <div  dangerouslySetInnerHTML={{ __html: tastryScript?.split("<script")?.[0]} } />}

  </>
)
}

export const query = graphql`
  query VerboseContentPage($id: String!) {
    prismicSwVerboseContentPage(id: { eq: $id }) {
      data {
        bannerBackgroundColor: banner_background_color
        bannerImage: banner_image {
          url
        }
        bannerText: banner_text {
          text
        }
        bannerTextColor: banner_text_color
        body {
          richText
        }
        body1 {
          ... on PrismicSwVerboseContentPageDataBody1Agreement {
            slice_type
            primary {
              rich_text {
                text
              }
            }
          }
        }
        seoDescription: seo_description {
          text
        }
        seoTitle: seo_title {
          text
        }
      }
    }
  }
`

export default VerboseContentPageTemplate

export const Head: HeadFC<DataProps> = ({ data }: HeadProps<DataProps>) => (
  <>
    <title>{data.prismicSwVerboseContentPage?.data.seoTitle?.text || 'Scout Wild™'}</title>
    <meta
      name="description"
      content={data.prismicSwVerboseContentPage?.data.seoDescription?.text || ''}
    />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
  </>
)
